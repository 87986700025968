<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-08-04 11:31:56
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-04 14:14:21
-->
<template>
  <div class="confirm_discount">
    <a-modal title="推送K3" v-model="visible" :width="800" @ok="onSubmit" okText="确定" :maskClosable="false">
      <a-form-model
        ref="form"
        :model="rowData"
        :rules="formRule"
        layout="horizontal"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-form-model-item label="K3发货人" prop="k3username">
          <a-input v-model="rowData.k3username" placeholder="请填写K3系统中对应的发货人名字"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'Push-K3',
  data() {
    return {
      visible: false,
      rowData: {},
      order:{},
      ids : '',
      // 表单验证
      formRule: {
        k3username: [{ required: true, message: '请填写K3系统中对应的发货人名字', trigger: 'blur' }],
      },
    }
  },

  computed: {},

  created() {},

  methods: {
    // onShow(ids) {
    //   this.visible = true
    //   this.rowData = {},
    //   //this.ids = ids,
    //   this.rowData.billIds = ids
    // },
    getData(opt){
      this.visible = true
      this.rowData = {}
        //this.ids = ids,
      this.rowData.billIds = opt.map((item) => item.id).join(',')
    },

    // 提交
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        const p = {
          billIds: this.rowData.billIds,
          k3username: this.rowData.k3username
        }
        this.axios.get(`/api/order/pushk3/batbatchPushK3`, {params: p}).then(res => {
          this.$message.success(res.message)
          this.$bus.$emit('getList')
          this.visible = false
          //this.$emit('onSubmit',this.order)
        })
      })
    }
  },
}
</script>

<style lang='scss' scoped>
</style>